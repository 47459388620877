import React from "react";
import {graphql} from "gatsby"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "gatsby-plugin-react-i18next";

import Logo from "assets/icons/logo.svg";

export default function MailVerifiedPage() {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Container maxWidth="md">
      <div style={{marginLeft: "2rem"}}>
        <Box sx={{color: theme.palette.primary.main, p: 2}}>
          <Logo width={150}/>
        </Box>
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            sx={{marginBottom: 20, marginTop: 20}}
          >
            {t("pageTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: "center"}}>
          <strong>
            {t("description")}
          </strong>
        </Grid>
      </Grid>
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index", "welcome"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

